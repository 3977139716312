#tabla1_wrapper {
  visibility: hidden;
  height: 1px;
}
#tabla2_wrapper {
  visibility: hidden;
  height: 1px;
}
#tabla3_wrapper {
  visibility: hidden;
  height: 1px;
}
#tabla4_wrapper {
  visibility: hidden;
  height: 1px;
}
#tabla5_wrapper {
  visibility: hidden;
  height: 1px;
}
#tabla6_wrapper {
  visibility: hidden;
  height: 1px;
}
.border-radius {
  border-radius: 1.5rem;
}
