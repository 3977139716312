html,
body {
  height: 100%;
}

body {
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}
.page-link {
  position: inherit !important;
}
.padding-main {
  padding: 0 0 200px 0px !important;
}
.hover-translate-y-n10:hover {
  transform: translateY(-10px) !important;
}
.hover-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgb(31 45 61 / 13%) !important;
}
.bg-section-secondary {
  background-color: #fafbfe !important;
}
.card-menu {
  color: #ffffff !important;
  background: #6f6f71 !important;
  border-radius: 20px !important;
}
.card-menu:hover {
  color: #ffffff !important;
  background-color: #d67b38 !important;
  border-color: #d67b38 !important;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #d67b38 !important;
  color: white;
  border-radius: 0.3rem;
}
.padding-top {
  padding-top: 40px;
}
.padding-row-documentos-adjuntos {
  padding: 0 0 10px 0 !important;
}
.clave {
  cursor: pointer;
  color: #d67b38 !important;
}
.btn-gris {
  color: #ffffff !important;
  background-color: #6f6f71 !important;
  border-color: #6f6f71 !important;
  border-radius: 1.5rem !important;
}
.btn-gris:hover {
  color: #ffffff !important;
  background-color: #d67b38 !important;
  border-color: #d67b38 !important;
}
.form-control {
  border-radius: 1.5rem !important;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
  padding: 10%;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  /* margin-bottom: 10px; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-crear {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: auto;
}
.form-crear .form-padding {
  padding: 0 0 10px 0;
}
.form-crear .checkbox {
  font-weight: 400;
  padding: 10%;
}
.form-crear .form-control {
  /* position: relative; */
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-crear .form-control:focus {
  z-index: 2;
}
/**/
.form-crear-cliente {
  width: 100%;
  max-width: 900px;
  padding: 15px;
  margin: auto;
}
.form-crear-cliente .form-padding {
  padding: 0 0 10px 0;
}
.form-crear-cliente .checkbox {
  font-weight: 400;
  padding: 10%;
}
.form-crear-cliente .form-control {
  /* position: relative; */
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-crear-honramiento .form-control:focus {
  z-index: 2;
}
/**/
.form-crear-honramiento {
  width: 100%;
  max-width: 900px;
  padding: 15px;
  margin: auto;
}
.form-crear-honramiento .form-padding {
  padding: 0 0 10px 0;
}
.form-crear-honramiento .checkbox {
  font-weight: 400;
  padding: 10%;
}
.form-crear-honramiento .form-control {
  /* position: relative; */
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-crear-honramiento .form-control:focus {
  z-index: 2;
}
/**/
.breadcrumb {
  background-color: #f8f9fa !important;
}
/*----------step-wizard------------*/
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}
.signup-step-container {
  padding: 150px 0px;
  padding-bottom: 60px;
}
.wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
}

.wizard > div.wizard-inner {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
}

.connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 16px;
  color: #0e214b;
  font-weight: 500;
  border: 1px solid #ddd;
}
span.round-tab i {
  color: #555555;
}
.wizard li.active span.round-tab {
  background: #0db02b;
  color: #fff;
  border-color: #0db02b;
}
.wizard li.active span.round-tab i {
  color: #5bc0de;
}
.wizard .nav-tabs > li.active > a i {
  color: #0db02b;
}

.wizard .nav-tabs > li {
  width: 25%;
}
.mora-legal-red {
  color: red;
}
.wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}
.wizard .nav-tabs > li a i {
  position: absolute;
  top: -15px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: 700;
  color: #000;
}

.wizard .nav-tabs > li a:hover {
  background: transparent;
}

.wizard .tab-pane {
  position: relative;
  padding-top: 20px;
}

.wizard h3 {
  margin-top: 0;
}
.prev-step,
.next-step {
  font-size: 13px;
  padding: 8px 24px;
  border: none;
  border-radius: 4px;
  margin-top: 30px;
}
.next-step {
  background-color: #0db02b;
}
.skip-btn {
  background-color: #cec12d;
}
.step-head {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.term-check {
  font-size: 14px;
  font-weight: 400;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.footer-link {
  margin-top: 30px;
}
.all-info-container {
}
.list-content {
  margin-bottom: 10px;
}
.list-content a {
  padding: 10px 15px;
  width: 100%;
  display: inline-block;
  background-color: #f5f5f5;
  position: relative;
  color: #565656;
  font-weight: 400;
  border-radius: 4px;
}
.list-content a[aria-expanded="true"] i {
  transform: rotate(180deg);
}
.list-content a i {
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.5s;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #fdfdfd;
}
.list-box {
  padding: 10px;
}
.signup-logo-header .logo_area {
  width: 200px;
}
.signup-logo-header .nav > li {
  padding: 0;
}
.signup-logo-header .header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-inline li {
  display: inline-block;
}
.pull-right {
  float: right;
}
.password-icon {
  float: right;
  position: relative;
  margin: -42px 10px 0 0;
  cursor: pointer;
}
/* Make circles that indicate the steps of the form: */
.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: #4caf50;
}
@media (max-width: 767px) {
  .sign-content h3 {
    font-size: 40px;
  }
  .wizard .nav-tabs > li a i {
    display: none;
  }
  .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }
  .signup-logo-header .logo_area {
    margin-top: 0;
  }
  .signup-logo-header .header-flex {
    display: block;
  }
}
.btn-flotante {
  font-size: 16px; /* Cambiar el tamaño de la tipografia */
  text-transform: uppercase; /* Texto en mayusculas */
  font-weight: bold; /* Fuente en negrita o bold */
  color: #141414; /* Color del texto */
  border-radius: 5px; /* Borde del boton */
  letter-spacing: 2px; /* Espacio entre letras */
  background-color: #007bff; /* Color de fondo */
  padding: 18px 30px; /* Relleno del boton */
  position: fixed;
  bottom: 140px;
  right: 40px;
  transition: all 300ms ease 0ms;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  z-index: 99;
}
.btn-flotante:hover {
  background-color: #007bff; /* Color de fondo al pasar el cursor */
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
  .btn-flotante {
    font-size: 14px;
    padding: 12px 20px;
    bottom: 200px;
    right: 20px;
    color: #141414;
  }
}
.border-radius {
  border-radius: 1.5rem !important;
}
/**/
.register {
  background: -webkit-linear-gradient(left, #d67b38, #6f6f5f);
  /* margin-top: 3%;
  padding: 3%; */
}
.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}
.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}
.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}
.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}
.register .register-form {
  padding: 10%;
  margin-top: 10%;
}
.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}
.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}
.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.register .nav-tabs .nav-link:hover {
  border: none;
}
.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}
/**/